import React, {useRef, useState, useEffect, useCallback} from "react"
import { Link, graphql } from 'gatsby'
import Layout from "../components/layout"
import Img from 'gatsby-image';
import styled from 'styled-components';


const Thumbnail = styled.div`
  .gatsby-image-wrapper{
    height:360px ;
    width:640px ;
    @media(max-width:1350px){
      height:270px;
      width:480px;
    }
    @media(max-width:1000px){
      width:100%;
      height:auto;
    }
  }
  
`

const LinkWrapper = styled.div`
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  width:1280px;
  margin:150px auto 0 auto;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  @media(max-width:1350px){
    width:960px
  }
  @media(max-width:1000px){
    display:block;
    width:100%;
  }
  .title{
    position:absolute;
    z-index:100;
    color:#ffffff;
    background:#000000;
    padding:20px;
    animation: titleAnimate 0.5s ease;

  }
  >div{
    position:relative;
    .title{
      right:0;
    }
  }

`

const ThumbnailCard = ({product})=>{
  const [hovered, setHovered] = useState(false)

  const enter = useCallback(()=>{
     //setShowFull(showFull=>!showFull)
     setHovered(true)
     //setShowFull(s=>!s)//If you want toggle behaviour
  },[])
  const leave = (e) => {
    setHovered(false);
  }
  return (
    <Thumbnail onMouseEnter={enter} onMouseLeave={leave}>
    {hovered ? 
    <>
      <div className="title">{product.titleOfProject}</div>
      {product.thumbnailImageHovered ? 
      <Img className="fade" fadeIn={false} fluid={product.thumbnailImageHovered.fluid} /> : null} 
      </>
    :
      <>
        {product.thumbnailImage ? 
        <Img fadeIn={false} fluid={product.thumbnailImage.fluid} /> : null}
      </>
    }
  </Thumbnail>  
  )
}

const IndexPage = ({data}) => {

      // function fetchHello(e){
      //   fetch("/.netlify/functions/hello")
      // .then(response => response.json())
      // .then(console.log)
      // }

      //test


  return(
    <Layout>
    <LinkWrapper>

    {data.allDatoCmsProject.edges.map(({ node: product }) => {
      return(
        <>
          {product.hideProject &&
            <div  key={product.id}>      
                <Link to= {`/projects/${product.slug}`}> 
                  <ThumbnailCard product={product}/>
                </Link>
            </div>
          }
      </>
      )

    })}

     </LinkWrapper>
    
  </Layout>
  )
  
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    
    allDatoCmsProject(sort: { fields: [position], order: ASC }){
      edges{
        node{
          id
          titleOfProject
          slug
          hideProject
          thumbnailImage {
            fluid(maxWidth:1280) {
              ...GatsbyDatoCmsFluid
            }
          }
          thumbnailImageHovered {
            fluid(maxWidth:1280) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`